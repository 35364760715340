import React from 'react'
import styled from 'styled-components'
import colors from '../constants/colors'

const StyledButton = styled.a`
    color: ${(props) => (props.color ? props.color : colors.grey)};
    background-color: ${(props) =>
        props.backgroundColor ? props.backgroundColor : colors.darkBlue};
    border: 1px solid
        ${(props) =>
            props.backgroundColor ? props.backgroundColor : colors.darkBlue};
    font-family: halogen, sans-serif;
    line-height: 1;
    text-transform: uppercase;
    font-weight: 700;
    padding: 1em 2em;
    transition: background-color ease 0.6s, color ease 0.6s;
    display: inline-block;

    ${(props) =>
        props.skew &&
        `
    
    transform: skewX(-10deg);

    span {
        display: inline-block;
        transform: skewX(10deg);
    }
    
    `}

    &:hover {
        background-color: transparent;
        color: ${(props) =>
            props.backgroundColor ? props.backgroundColor : colors.darkBlue};
    }
`

const Button = (props) => {
    const { color, backgroundColor, children, ...rest } = props

    return (
        <StyledButton
            className="button"
            color={color}
            backgroundColor={backgroundColor}
            {...rest}
        >
            <span>{children}</span>
        </StyledButton>
    )
}

Button.defaultProps = {
    skew: true,
}

export default Button
