import React from 'react'
import styled, { keyframes } from 'styled-components'
import { StaticImage } from 'gatsby-plugin-image'
import Starfield from './starfield'
import { motion, useViewportScroll, useTransform } from 'framer-motion'
import useWindowSize from '../hooks/useWindowSize'
import colors from '../constants/colors'

const maxHeight = `min(400px,50vh)`

const fadeIn = keyframes`
    0% {
        opacity: 0
    }
    100% {
        opacity: 1
    }
`

const cloud1Animation = keyframes`
    0% {
        opacity: 1;
        transform: translate3d(0px,0px,0px) scale(1);
    }
    50% {
        opacity: 0.95;
        transform: translate3d(10%,20px,0px) scale(1.1);
    }
    100% {
        opacity: 1;
        transform: translate3d(0px,0px,0px) scale(1);
    }
`

const cloud2Animation = keyframes`
    0% {
        transform: translate3d(0%,-25px,0px);
    }
    55% {
        transform: translate3d(-15%,0,0px);
    }
    100% {
        transform: translate3d(0%,-25px,0px);
    }
`

const AboveFold = styled.div`
    min-height: 100vh;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: ${colors.black};

    .mountain {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        z-index: 100;
        transform: translateZ(0px);
        animation: ${fadeIn} ease-in 0.75s;
        height: ${maxHeight};
    }

    .cloud {
        position: absolute;
        z-index: 10;
    }

    .cloud-1 {
        right: 0%;
        bottom: 12%;
        animation: ${cloud1Animation} 60s linear 0s infinite;
    }
    .cloud-2 {
        left: -5%;
        bottom: 15%;
        animation: ${cloud2Animation} 60s linear 0s infinite;
    }
`

const MainHeading = styled(motion.h1)`
    font-size: max(5vw, 3.5rem);
    text-align: center;
    position: relative;
    z-index: 1;
`
const CloudWrapper = styled(motion.div)`
    position: absolute;
    bottom: 0;
    left: 0;
    height: ${maxHeight};
    width: 100%;
    z-index: ${(props) => (props.zIndex ? props.zIndex : `100`)};
    animation: ${fadeIn} ease-in 0.75s;
`

const Cloud = (props) => {
    const { children, zIndex } = props
    const { scrollY } = useViewportScroll()
    const { height: windowHeight } = useWindowSize()

    const opacityRange = [1, 0.75]
    const yRange = [0, -100]
    const scrollRange = [0, windowHeight]

    const y = useTransform(scrollY, scrollRange, yRange)
    const opacity = useTransform(scrollY, scrollRange, opacityRange)

    return (
        <CloudWrapper zIndex={zIndex} style={{ y }}>
            {children}
        </CloudWrapper>
    )
}

const MotionHeading = ({ children }) => {
    const { scrollY } = useViewportScroll()
    const { height: windowHeight } = useWindowSize()

    const opacityRange = [1, 0]
    const yRange = [0, windowHeight / 2]
    const scrollRange = [0, windowHeight]

    const y = useTransform(scrollY, scrollRange, yRange)
    const opacity = useTransform(scrollY, scrollRange, opacityRange)

    return <MainHeading style={{ opacity, y }}>{children}</MainHeading>
}

const Hero = ({ children }) => {
    return (
        <AboveFold>
            <StaticImage
                src="../images/mountain.png"
                alt="mountain"
                style={{ zIndex: 100 }}
                imgStyle={{ zIndex: 100 }}
                placeholder="tracedSVG"
                className="mountain"
            />
            <Cloud>
                <StaticImage
                    src="../images/cloud1.png"
                    alt="clouds"
                    placeholder="tracedSVG"
                    className="cloud cloud-1"
                />
            </Cloud>
            <Cloud zIndex={99}>
                <StaticImage
                    src="../images/cloud2.png"
                    alt="clouds"
                    placeholder="tracedSVG"
                    className="cloud cloud-2"
                />
            </Cloud>
            <MotionHeading>{children}</MotionHeading>
            <Starfield />
        </AboveFold>
    )
}

export default Hero
