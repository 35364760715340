import React from 'react'
import styled from 'styled-components'
import colors from '../constants/colors'
import mediaQueries from '../constants/mediaQueries'

const clipPath = `polygon(10% 0%,100% 0%,90% 100%,0% 100%)`

const ImgWrapper = styled.div`
    position: relative;
    margin-bottom: 10%;
    ${mediaQueries.sm} {
        margin-bottom: 0;
        ${(props) =>
            props.position === 'left' &&
            `
            margin-right: 10%;
        `}

        ${(props) =>
            props.position === 'right' &&
            `
            margin-left: 10%;
        `}
    }
`

const ImgClip = styled.div`
    clip-path: ${clipPath};
`

const Skew = styled.div`
    position: absolute;
    height: 100%;
    width: 100%;
    bottom: -5%;
    background-color: ${colors.lightBlue};
    clip-path: ${clipPath};

    ${(props) =>
        props.position === 'left' &&
        `
        left: -5%;
    `}

    ${(props) =>
        props.position === 'right' &&
        `
        right: -5%;
    `}
`

const Image = (props) => {
    const { position, children, ...rest } = props

    return (
        <ImgWrapper position={position} {...rest}>
            <Skew position={position} />
            <ImgClip>{children}</ImgClip>
        </ImgWrapper>
    )
}

Image.defaultProps = {
    position: 'left',
}

export default Image
