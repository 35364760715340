import React, { useEffect, useRef } from 'react'
import styled from 'styled-components'
import colors from '../constants/colors'

const CanvasContainer = styled.div`
    &,
    & > canvas {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        background-color: ${colors.black};
    }
`

/**
 * https://stackoverflow.com/questions/7814398/a-glow-effect-on-html5-canvas
 * https://developer.mozilla.org/en-US/docs/Web/API/Canvas_API/Tutorial/Basic_animations
 * https://www.w3resource.com/html5-canvas/html5-canvas-shadow.php
 * http://thenewcode.com/81/Make-A-Starfield-Background-with-HTML5-Canvas
 * https://medium.com/@pdx.lucasm/canvas-with-react-js-32e133c05258
 */

const Starfield = () => {
    const ref = useRef(null)

    useEffect(() => {
        const canvas = ref.current

        function getRandomInt(min, max) {
            min = Math.ceil(min)
            max = Math.floor(max)
            return Math.floor(Math.random() * (max - min) + min) //The maximum is exclusive and the minimum is inclusive
        }

        if (canvas) {
            const context = canvas.getContext('2d')
            const stars = 200

            for (let i = 0; i < stars; i++) {
                const x = Math.random() * canvas.offsetWidth
                const y = Math.random() * canvas.offsetHeight
                const size = Math.random() * 2
                const delay = Math.random() * 5
                const blur = Math.random() * 5

                context.fillStyle = `rgba(255,255,255,${getRandomInt(0.5, 1)})`
                context.shadowBlur = blur
                context.shadowColor = `rgba(255,255,255,${getRandomInt(
                    0.5,
                    1
                )})`
                context.fillRect(x, y, size, size)
            }
        }
    }, [ref])

    return (
        <CanvasContainer>
            <canvas id="starfield" width="750" height="500" ref={ref} />
        </CanvasContainer>
    )
}

export default Starfield
