import React from 'react'
import styled from 'styled-components'
import { useForm } from 'react-hook-form'
import Button from './button'
import colors from '../constants/colors'
import ScrollSpy from './scrollspy'

const Background = styled.div`
    background-color: ${colors.black};
    z-index: 0;
    transform: translateY(-100%);
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    transition: transform 0.6s cubic-bezier(0.83, 0, 0.17, 1);
`

const S = {
    Form: styled.form`
        .button {
            width: 100%;
            display: block;
        }
    `,
    Input: styled.input`
        border: 1px solid ${colors.black};
        color: ${colors.black};
        position: relative;
        display: block;
        padding: 20px 15px;
        border-radius: 0;
        font-weight: 400;
        -webkit-appearance: none;
        width: 100%;
        background-color: transparent;
        transition: all 0.6s cubic-bezier(0.83, 0, 0.17, 1);
        outline: none;
        line-height: 1;
        z-index: 1;

        &::placeholder {
            color: ${colors.black};
        }

        &:focus {
            &,
            &::placeholder {
                color: ${colors.grey};
            }
            & + ${Background} {
                transform: translateY(0);
            }
        }
    `,
    Wrapper: styled.div`
        margin-bottom: 10px;
        position: relative;
        overflow: hidden;
        line-height: 1;
    `,
}

const Input = (props) => {
    const { label, register, type, name, required, ...rest } = props

    return (
        <S.Wrapper>
            <S.Input
                placeholder={label}
                type={type}
                {...rest}
                {...register(name, { required: required })}
            />
            <Background />
        </S.Wrapper>
    )
}

Input.defaultProps = {
    label: '',
    type: 'text',
    required: false,
}

const Form = () => {
    const { register, handleSubmit, reset } = useForm()

    const onSubmit = (e) => console.log(e)

    return (
        <S.Form onSubmit={handleSubmit(onSubmit)}>
            <ScrollSpy>
                <Input
                    label="First Name"
                    name="firstName"
                    required
                    register={register}
                />
            </ScrollSpy>
            <ScrollSpy>
                <Input
                    label="Last Name"
                    name="lastName"
                    required
                    register={register}
                />
            </ScrollSpy>
            <ScrollSpy>
                <Input
                    label="Phone Number"
                    type="tel"
                    name="phone"
                    required
                    register={register}
                />
            </ScrollSpy>
            <ScrollSpy>
                <Input
                    label="Email Address"
                    type="email"
                    name="email"
                    required
                    register={register}
                />
            </ScrollSpy>
            <ScrollSpy>
                <Button as="button" type="submit" skew={false}>
                    SUBMIT
                </Button>
            </ScrollSpy>
        </S.Form>
    )
}

export default Form
