import * as React from 'react'
import Layout from '../components/layout'
import { Container, Row, Column } from '../components/grid'
import { StaticImage } from 'gatsby-plugin-image'
import styled from 'styled-components'
import mediaQueries from '../constants/mediaQueries'
import colors from '../constants/colors'
import Button from '../components/button'
import { motion, useViewportScroll, useTransform } from 'framer-motion'
import useWindowSize from '../hooks/useWindowSize'
import useDimensions from '../hooks/useDimensions'
import Image from '../components/image'
import Parallax from '../components/parallax'
import Form from '../components/form'
import Hero from '../components/hero'
import ScrollSpy from '../components/scrollspy'

const Section = styled.section`
    padding-top: max(10vh, 100px);
    padding-bottom: max(10vh, 100px);
    position: relative;
`

const StrokedText = styled.span`
    color: #00000000;
    -webkit-text-fill-color: #00000000;
    -webkit-text-stroke-width: 1px;
    -webkit-text-stroke-color: ${colors.grey};
    letter-spacing: calc(1px - 0.05em);
    ${mediaQueries.sm} {
        -webkit-text-stroke-width: ${(props) =>
            props.size ? props.size : 2}px;
        letter-spacing: calc(
            ${(props) => (props.size ? props.size : 2)}px - 0.05em
        );
    }
`

const LargeStatement = styled.h2`
    text-align: center;

    span {
        display: inline-block;
        font-size: 0.75em;
    }
`

const BenefitsSection = styled(Section)`
    display: grid;
    grid-template-columns: repeat(6, minmax(0px, 1fr));
    column-gap: 2rem;

    ${mediaQueries.sm} {
        grid-template-columns: repeat(24, minmax(0px, 1fr));
    }
`

const BenefitTitle = styled.div`
    grid-column: 1 / -1;
    position: relative;

    h2 {
        font-size: calc(20px + 5vw);
        display: flex;
        flex-direction: column;

        ${mediaQueries.sm} {
            margin-bottom: -1em;
            line-height: 2;
        }

        .main,
        .blend {
            transform: translate3d(0px, 0px, 0px);
            position: relative;
            display: inline-flex;
        }

        .blend {
            position: absolute;
            top: 0px;
            left: 0px;
            width: 100%;
            z-index: 10;
            pointer-events: none;
            color: #00000000;
            -webkit-text-fill-color: #00000000;
            -webkit-text-stroke-width: 1px;
            -webkit-text-stroke-color: ${colors.grey};
        }
    }
`

const BenefitTitleWrap = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`

const BenefitTitleMask = styled.div`
    display: flex;
    align-items: flex-start;
    white-space: nowrap;
    position: relative;
    overflow: hidden;
`

const BenefitContent = styled.div`
    grid-column: 1 / -1;
    font-size: 1.15em;
    padding-top: 30px;
    ${mediaQueries.sm} {
        padding-top: calc(20px + 5vw);
        grid-column: ${(props) => (props.reverse ? `1 / 12` : `14 / 25`)};
    }
`

const BenefitImage = styled.div`
    grid-column: 2 / 6;
    ${mediaQueries.sm} {
        grid-column: ${(props) => (props.reverse ? `12 / 25` : `1 / 14`)};
        ${(props) => props.reverse && `order: 9999`}
    }
`

const SubHeading = styled.h4`
    padding-left: 2em;
    position: relative;

    &:before {
        content: '';
        height: 1px;
        width: 1.5em;
        position: absolute;
        left: 0;
        top: 0.6em;
        border: 1px solid currentColor;
    }
`

const ParallaxOverlaySection = styled(Section)`
    overflow: hidden;

    .gatsby-image-wrapper,
    .parallax-wrap,
    .parallax {
        position: absolute;
        left: 0;
        width: 100%;
    }
    .parallax-wrap {
        mix-blend-mode: overlay;
        opacity: 0.15;
        top: -50px;
        bottom: -50px;
    }
    .gatsby-image-wrapper,
    .parallax {
        top: 0;
        height: 100%;
    }
`

const FormSection = styled(Section)`
    color: ${colors.black};

    ${Container} {
        margin-top: 25vh;

        ${mediaQueries.sm} {
            margin-top: 0;
        }
    }

    .gatsby-image-wrapper {
        position: absolute;
        top: 0;
        right: 0;
        width: 100%;
        height: 25vh;
        clip-path: polygon(10% 0%, 100% 0%, 100% 100%, 0% 100%);

        ${mediaQueries.sm} {
            width: 55%;
            height: 100%;
        }
    }
`

const ScrollingTextWrap = styled(motion.div)`
    display: flex;
    flex-flow: row nowrap;

    h2 {
        white-space: nowrap;
        margin-left: 0.25em;
        margin-right: 0.25em;
        font-size: 7vw;
    }
`

const ScrollingText = ({ box, reverse }) => {
    const { scrollY } = useViewportScroll()
    const { width: windowWidth, height: windowHeight } = useWindowSize()
    const [ref, { width: elementWidth }] = useDimensions()

    const top = box.top ? box.top : 0
    const sectionHeight = box.height ? box.height : 0

    const start = reverse ? 0 : -1 * elementWidth
    const end = reverse ? -1 * elementWidth : 0

    const xRange = [start, end]
    const scrollRange = [top - windowHeight, top + sectionHeight]

    const x = useTransform(scrollY, scrollRange, xRange)

    const Heading = () => (
        <h2>
            Altitude is for <StrokedText>everyone</StrokedText>
        </h2>
    )
    const length = 6

    return (
        <ScrollingTextWrap ref={ref} style={{ x }}>
            {[...Array(length).keys()].map((index) => {
                return React.cloneElement(<Heading />, { key: index })
            })}
        </ScrollingTextWrap>
    )
}

const ScrollingTextSection = () => {
    const [ref, dimensions] = useDimensions()

    return (
        <Section ref={ref}>
            <ScrollingText box={dimensions} reverse={false} />
            <ScrollingText box={dimensions} reverse={true} />
            <ScrollingText box={dimensions} reverse={false} />
        </Section>
    )
}

const ParallaxOverlay = ({ children }) => {
    return (
        <ParallaxOverlaySection
            style={{
                backgroundColor: colors.darkBlue,
                textAlign: 'center',
            }}
        >
            {children}
            <div className="parallax-wrap">
                <Parallax>
                    <StaticImage src="../images/maskedimg.jpeg" alt="ALT" />
                </Parallax>
            </div>
        </ParallaxOverlaySection>
    )
}

const IndexPage = () => {
    return (
        <Layout>
            <Hero>
                <Container>
                    <ScrollSpy delay={0.5}>Altitude is for</ScrollSpy>
                    <ScrollSpy delay={0.75}>
                        <StrokedText>everyone</StrokedText>
                    </ScrollSpy>
                </Container>
            </Hero>
            <Section style={{ backgroundColor: colors.black, zIndex: 10 }}>
                <Container>
                    <Row alignX="center">
                        <Column sm={75}>
                            <ScrollSpy>
                                <h2>
                                    Why invest in an Air Locker Training studio
                                    franchise?
                                </h2>
                            </ScrollSpy>
                            <ScrollSpy delay={0.25}>
                                <p>
                                    In a market saturated with similar versions
                                    of each other, an Air Locker Training
                                    franchise gives you an extremely strong
                                    point of difference.
                                </p>
                            </ScrollSpy>
                            <ScrollSpy delay={0.5}>
                                <p>
                                    A full 360-degree support package offers
                                    property search specialists, lease
                                    negotiation experts, architect and town
                                    planning specialists, studio interior
                                    designers, gym fit-out professionals,
                                    bespoke digital marketing solutions, an
                                    in-house coaching development program, and
                                    an experienced, professional support team.
                                </p>
                            </ScrollSpy>
                        </Column>
                    </Row>
                </Container>
            </Section>
            <ParallaxOverlay>
                <Container
                    style={{
                        marginTop: 100,
                        marginBottom: 100,
                        position: 'relative',
                        zIndex: 200,
                    }}
                >
                    <Row alignX="center">
                        <Column sm={85}>
                            <ScrollSpy>
                                <LargeStatement>
                                    <span>
                                        Your journey to become an Air Locker
                                        franchisee is a true partnership, with
                                        all bases covered.
                                    </span>
                                </LargeStatement>
                            </ScrollSpy>
                            <ScrollSpy delay={0.25}>
                                <Button
                                    href="#signup"
                                    color={colors.darkBlue}
                                    backgroundColor={colors.grey}
                                >
                                    Sign Up Here
                                </Button>
                            </ScrollSpy>
                        </Column>
                    </Row>
                </Container>
            </ParallaxOverlay>
            <Section>
                <Container>
                    <BenefitsSection>
                        <BenefitTitle>
                            <h2>
                                <BenefitTitleWrap>
                                    <BenefitTitleMask>
                                        <div className="main">
                                            <ScrollSpy>Invest</ScrollSpy>
                                        </div>
                                        <div className="blend">
                                            <ScrollSpy>Invest</ScrollSpy>
                                        </div>
                                    </BenefitTitleMask>
                                </BenefitTitleWrap>
                            </h2>
                        </BenefitTitle>
                        <BenefitImage>
                            <Parallax>
                                <Image>
                                    <StaticImage
                                        src="../images/img1.jpg"
                                        alt="ALT FITNESS"
                                    />
                                </Image>
                            </Parallax>
                        </BenefitImage>
                        <BenefitContent>
                            <ScrollSpy>
                                <p>
                                    Air Launcher Dedicated HQ marketing campaign
                                    that takes your franchise on a strategic
                                    marketing journey by removing the guesswork.
                                </p>
                            </ScrollSpy>
                            <ScrollSpy delay={0.25}>
                                <p>
                                    We'll ensure your brand is front and center.
                                </p>
                            </ScrollSpy>
                            <ScrollSpy delay={0.5}>
                                <Button href="#signup">Get Started Now</Button>
                            </ScrollSpy>
                        </BenefitContent>
                    </BenefitsSection>
                    <BenefitsSection>
                        <BenefitTitle>
                            <h2>
                                <BenefitTitleWrap>
                                    <BenefitTitleMask>
                                        <div className="main">
                                            <ScrollSpy>Finance</ScrollSpy>
                                        </div>
                                        <div className="blend">
                                            <ScrollSpy>Finance</ScrollSpy>
                                        </div>
                                    </BenefitTitleMask>
                                </BenefitTitleWrap>
                            </h2>
                        </BenefitTitle>
                        <BenefitImage reverse>
                            <Parallax>
                                {' '}
                                <Image position="right">
                                    <StaticImage
                                        src="../images/img3.jpg"
                                        alt="ALT FITNESS"
                                    />
                                </Image>
                            </Parallax>
                        </BenefitImage>
                        <BenefitContent reverse>
                            <ScrollSpy>
                                <SubHeading>
                                    Small Start-Up Cost, Big Return
                                </SubHeading>
                            </ScrollSpy>
                            <ScrollSpy delay={0.25}>
                                <p>
                                    The costs to entry for an Air Locker gym are
                                    minimal in comparison to competing
                                    franchises.
                                </p>
                            </ScrollSpy>
                            <ScrollSpy delay={0.5}>
                                <p>
                                    Our model allows owners to open their first
                                    Air Locker gym with minimal personal
                                    capital, allowing you to use excess funds
                                    for the marketing and growth of your
                                    business.
                                </p>
                            </ScrollSpy>
                            <ScrollSpy delay={0.75}>
                                <Button href="#signup">Get Started Now</Button>
                            </ScrollSpy>
                        </BenefitContent>
                    </BenefitsSection>
                    <BenefitsSection>
                        <BenefitTitle>
                            <h2>
                                <BenefitTitleWrap>
                                    <BenefitTitleMask>
                                        <div className="main">
                                            <ScrollSpy>Experience</ScrollSpy>
                                        </div>
                                        <div className="blend">
                                            <ScrollSpy>Experience</ScrollSpy>
                                        </div>
                                    </BenefitTitleMask>
                                </BenefitTitleWrap>
                            </h2>
                        </BenefitTitle>
                        <BenefitImage>
                            <Parallax>
                                <Image>
                                    <StaticImage
                                        src="../images/img4.jpg"
                                        alt="ALT FITNESS"
                                    />
                                </Image>
                            </Parallax>
                        </BenefitImage>
                        <BenefitContent>
                            <ScrollSpy>
                                {' '}
                                <SubHeading>
                                    The <u>franchisee</u> Experience
                                </SubHeading>
                            </ScrollSpy>
                            <ScrollSpy delay={0.25}>
                                <p>
                                    You'll receive support systems and tools to
                                    improve the performance and efficiency of
                                    your studio, starting with access to our
                                    AirPac, an “all-you-need-to-know” resource
                                    to successfully establish and grow your
                                    business.
                                </p>
                            </ScrollSpy>
                            <ScrollSpy delay={0.5}>
                                <Button href="#signup">Get Started Now</Button>
                            </ScrollSpy>
                        </BenefitContent>
                    </BenefitsSection>
                </Container>
            </Section>
            <ScrollingTextSection />
            <FormSection style={{ backgroundColor: colors.grey }} id="signup">
                <StaticImage src="../images/img2.jpg" alt="ALT" />
                <Container>
                    <Row>
                        <Column sm={40}>
                            <ScrollSpy>
                                <SubHeading>
                                    Interested in owning your own franchise?
                                    Fill out the form below to get started.
                                </SubHeading>
                            </ScrollSpy>
                            <Form />
                        </Column>
                    </Row>
                </Container>
            </FormSection>
        </Layout>
    )
}

export default IndexPage
