import React from 'react'
import { motion } from 'framer-motion'
import styled from 'styled-components'

const SpyWrapper = styled.div`
    position: relative;
`

const ScrollSpy = (props) => {
    const { children, visible, hidden, duration, ease, delay, ...rest } = props

    const variants = {
        visible: visible,
        hidden: hidden,
    }

    return (
        <SpyWrapper {...rest}>
            <motion.div
                variants={variants}
                initial="hidden"
                whileInView="visible"
                viewport={{ once: true }}
                transition={{
                    duration: duration,
                    ease: ease,
                    delay: delay,
                }}
            >
                {children}
            </motion.div>
        </SpyWrapper>
    )
}

ScrollSpy.defaultProps = {
    visible: { y: '0%', opacity: 1, overflow: 'unset' },
    hidden: { y: '100%', opacity: 0, overflow: 'hidden' },
    duration: 1.2,
    ease: [0.19, 1, 0.22, 1],
    delay: 0,
}

export default ScrollSpy
